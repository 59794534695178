import React from "react";
import TeamMember from "./TeamMember";
import speaker1 from "../assets/speakers/Shaik_Rafi.jpg";
import speaker2 from "../assets/speakers/Sumantra Dutta.jpg";
import speaker3 from "../assets/speakers/sireesha.jpg";
import speaker4 from "../assets/speakers/Dr.Nsandhya.jpg";
import speaker5 from "../assets/speakers/Anoop_M.jpg";
import speaker6 from "../assets/speakers/AnudeepPeddi.jpg";
import speaker7 from "../assets/speakers/Jayanthi_Sivaswamy.jpg";
import speaker8 from "../assets/speakers/shailesh_sakri.jpg";
import speaker9 from "../assets/speakers/M_Tanveer.jpg";
import speaker10 from "../assets/speakers/Shabrinath.jpg";
import speaker11 from "../assets/speakers/Ramneek_kalra.jpg";
import speaker12 from "../assets/speakers/Bharat_Gupta.jpg";
import speaker13 from "../assets/speakers/Vijaya_Durga.jpg";

function Speakers(props) {
  return (
    <section className={props.classes} id="speakers">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Speakers</h2>
          </div>
        </div>
        <div className="row my-3">
          <TeamMember
            src={speaker3}
            alt="one"
            title="Ms.Sireesha Jasthi"
            designation={`Assistant Consultant, Tata Consultancy Services`}
          />
          <TeamMember
            src={speaker4}
            alt="one"
            title="Dr. N. Sandhya"
            designation={`Professor & Head, Department of AIML & IoT,VNRVJIET`}
          />
          <TeamMember
            src={speaker5}
            alt="one"
            title="Dr.Anoop M. Namboodiri"
            designation={`Associate Professor, IIIT H, Ph.D (Michigan State University, USA) 
            `}
          />

          <TeamMember
            src={speaker6}
            alt="one"
            title="Mr. P. Anudeep"
            designation={` Product Architecture Consultant, Data Engineering, Octahedron LLC
            Dallas, Texas, USA
            `}
          />
        </div>
        <div className="row my-3">
          <div className="col-sm-1"></div>

          <TeamMember
            src={speaker7}
            alt="one"
            title="Dr.Jayanthi Sivaswamy"
            designation={`Professor, IIITH, Hyderabad`}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={speaker8}
            alt="one"
            title="Mr. Shailesh Sakri"
            designation={`Director, Automotive Audio Software, Harman International &
            Chair, IEEE SPS Bangalore `}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={speaker9}
            alt="one"
            title="Dr. M. Tanveer"
            designation={`Associate Professor, Ramanujan Fellow - Discipline of Mathematics,
            IIT-Indore`}
          />
        </div>
        <div className="row my-3">
          <div className="col-sm-1"></div>
          <TeamMember
            src={speaker10}
            alt="one"
            title="Mr. B. B. Shabarinath"
            designation={` Assistant Professor, Department of ECE, VNRVJIET, Hyderabad `}
          />
          <div className="col-sm-4"></div>
          <TeamMember
            src={speaker11}
            alt="one"
            title="Mr.Ramneek Kalra"
            designation={`IEEE Impact Creator`}
          />
        </div>
      </div>
    </section>
  );
}

export default Speakers;
