import React from "react";
import TeamMember from "./TeamMember";
import president from "../assets/AdvisoryCommittee/President_DNRao.jpg";
import gensec from "../assets/AdvisoryCommittee/GenSec_Harischandra.jpg";
import amitKumar from "../assets/AdvisoryCommittee/AmitKumar.jpg";
import chairLalitha from "../assets/AdvisoryCommittee/Chair_Lalitha.jpg";
import gvvSharma from "../assets/AdvisoryCommittee/GVVSharma.jpg";
import principal from "../assets/AdvisoryCommittee/Principal_CDNaidu.jpg";
import ieeeSec from "../assets/AdvisoryCommittee/SecretaryIEEE_BalaPrasad.jpg";
import treasurer from "../assets/AdvisoryCommittee/Treasurer_NookalaSrinivas.jpg";
import guest1 from "../assets/speakers/Guest1_DrRamaSubbaReddy.jpg";
import directorSandhya from "../assets/AdvisoryCommittee/DrSandhyaKode.JPG";
import chairYP from "../assets/AdvisoryCommittee/ChairYP_VamsiKrishna.jpg";
import drAnuradha from "../assets/AdvisoryCommittee/DrAnuradha.jpg";
import drPoonam from "../assets/AdvisoryCommittee/DrPoonam.jpg";
import director from "../assets/AdvisoryCommittee/Director_VNR.jpg";
import sureshbabu from "../assets/Team/sureshbabu.jpg";
import jsrao from "../assets/Team/JSRaoSir.jpg";
import ramneek from "../assets/Team/ramneek.jpg";
import hod from "../assets/Team/RajendraPrasad.jpg";
import kode from "../assets/Team/kodedurgaprasad.jpg";
import vidyasagar from "../assets/Team/vidyasagar.jpg";
import venkatesh from "../assets/Team/venkatesh.jpg";
import sreenivas from "../assets/Team/sreenivas.jpg";
import subhanandarao from "../assets/Team/subhanandarao.jpg";
import chennakeshavarao from "../assets/Team/chennakeshavarao.jpg";
import cdnaidu from "../assets/Team/cdnaidu.jpg";
import anuradha from "../assets/Team/anuradha.jpg";
import poonam from "../assets/Team/poonam.jpg";
import vijayalatha from "../assets/Team/vijayalatha.jpg";
import generalChair from "../assets/Team/Dr.Y.Padma Sai.jpg";
import anudeep from "../assets/Team/Anudeep.jpg";
import naveenkumar from "../assets/Team/naveenkumar.jpg";
import rajani from "../assets/Team/rajani.jpg";
import financeChair1 from "../assets/Team/Manjulasree.jpg";
import praveen from "../assets/AdvisoryCommittee/Praveen_K.jpg";
import DrAtulNegi from "../assets/AdvisoryCommittee/DrAtulNegi.jpg";

function Committee() {
  return (
    <section className="bg-light page-section pb-0" id="committee">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">
              Advisory Committee
            </h2>
          </div>
        </div>
        <div className="row mt-4">
          <TeamMember
            src={sureshbabu}
            alt="one"
            title="Mr. Daggubati Suresh Babu"
            designation={`President, Vignana Jyothi, Hyderabad`}
          />
          <TeamMember
            src={jsrao}
            alt="one"
            title="Er. JS Rao"
            designation={`General Secretary, VIGNANA JYOTHI, Hyderabad `}
          />

          <TeamMember
            src={president}
            alt="one"
            title="Dr. D. N. Rao"
            designation={`Former President, Vignana Jyothi, Hyderabad `}
          />
          <TeamMember
            src={gensec}
            alt="one"
            title="Sri. K. Harischandra Prasad"
            designation={`Former General Secretary, Vignana Jyothi, Hyderabad `}
          />
        </div>

        <div className="row">
          <div class="col-sm-3"></div>
          <TeamMember
            src={kode}
            alt="one"
            title="Mr. Kode Durga Prasad"
            designation={`Vice President, VNRVJIET, Hyderabad  `}
          />
          <TeamMember
            src={directorSandhya}
            alt="one"
            title="Dr. Sandhya Kode"
            designation={`Former Director, EnhanceEdu, IIIT Hyderabad  `}
          />
        </div>
        <div className="row">
          <div className="col-sm-1"></div>

          <TeamMember
            src={guest1}
            alt="one"
            title="Dr. M. Rama Subba Reddy"
            designation={`Professor, IIT Chennai  `}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={gvvSharma}
            alt="one"
            title="Dr. GVV Sharma "
            designation={`Associate Professor IIT Hyderabad  `}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={amitKumar}
            alt="Mr. Amit Kumar"
            title="Mr. Amit Kumar"
            designation={`Chair-Sensors Council, IEEE Hyderabad Section`}
          />
        </div>
        <div className="row">
          <TeamMember
            src={chairLalitha}
            alt=" Dr.Lalitha Vadlamani"
            title=" Dr. Lalitha Vadlamani"
            designation={`Chair, SPS ,IEEE Hyderabad Section`}
          />

          <TeamMember
            src={venkatesh}
            alt="one"
            title="Mr. N. Venkatesh"
            designation={`Interim IEEE vice Chair.`}
          />

          <TeamMember
            src={subhanandarao}
            alt="one"
            title="Dr. A. Subhananda Rao "
            designation={`Director, R&D, VNRVJIET`}
          />
          <TeamMember
            src={chennakeshavarao}
            alt=" one"
            title="Dr. B. Chennakesava Rao"
            designation={`Director for Advancement & Dean-Admin, VNRVJIET`}
          />
          <TeamMember
            src={cdnaidu}
            alt="one"
            title="Dr. C. D. Naidu"
            designation={`Principal,VNRVJIET`}
          />
          <TeamMember
            src={praveen}
            alt="one"
            title="Dr. K.Praveen Chand"
            designation={`Ph.D (Carnegie Mellon University, USA), Ads Ranking at Pinterest, San Francisco Bay Area`}
          />

          <TeamMember
            src={poonam}
            alt="one"
            title="Dr. Poonam Upadhyay"
            designation={`HoD, Dept.of EEE, VNRVJIET`}
          />

          <TeamMember
            src={vijayalatha}
            alt="one"
            title="Dr.Y. Vijayalatha"
            designation={`Chair,SAC,IEEE Hyderabad Section`}
          />
          <TeamMember
            src={hod}
            alt="HOD"
            title=" Dr. S. Rajendra Prasad"
            designation={`HoD, Dept.of ECE, VNR VJIET`}
          />
          <TeamMember
            src={anuradha}
            alt="HOD"
            title=" Dr. K. Anuradha"
            designation={`Professor, Dean Academics, VNR VJIET`}
          />

          <TeamMember
            src={DrAtulNegi}
            alt="one"
            title="Dr. Atul Negi"
            designation={`Interim Hyderabad Section Chair`}
          />
        </div>
      </div>
    </section>
  );
}

export default Committee;
