import React from "react";
import { Table } from "reactstrap";
import Speakers from "./Speakers";

function EventDetails() {
  return (
    <div className="container">
      <h1 className="my-4">Event Details</h1>
      <hr className="my-4 " color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <th scope="row">Type of event</th>
            <td>Physical Event</td>
          </tr>
          <tr>
            <th scope="row">Name of event</th>
            <td>
            IEEE SPS Student Branch Initiative on Latest Trends in Machine Learning for Signal Processing Applications
            </td>
          </tr>
          <tr>
            <th scope="row">Dates</th>
            <td>10th August - 12th August 2022</td>
          </tr>
          <tr>
            <th scope="row">Key Events</th>
            <td style={{color: '#069',fontWeight: 700, fontSize: '1.2em'}}>
            Technical Sessions

              <br />
              Poster Presentation
              <br />
              Panel Discussion
              <br />
              Live Project Demonstration
              <br />
            </td>
          </tr>
        </tbody>
      </Table>
      <h4 className="my-4 font-weight-bold">Technical Description</h4>
      <p>Machine Learning is the technology, that makes computers automatically learn and respond without being explicitly programmed. Speech processing, image processing, weather predictions and other major signal processing applications are currently using machine learning techniques to automate the mechanism of processing of signals. These technologies are showing their potential in getting solutions to many biomedical applications like predicting cancer, epileptic seizure, etc. Hence, there is a great need of knowing how machine learning algorithms are used for these purposes.
This event presents the advance signal processing concepts and tools needed to apply machine learning in distributed areas.  Acquire the knowledge and hands on experience of latest enhancements in deep learning techniques. Latest techniques for capturing, processing, manipulating, learning, and classifying signals will be discussed. Participants will have the familiarity of system design and development, procedures, architectures, and applications for signal processing and machine learning.
      </p>
      <h4 className="my-4 font-weight-bold">Topics to be Covered</h4>
      <p>
            • Introduction to fundamentals of machine learning
      <br />• Overview of various machine learning algorithms
      <br />• Developments in signal processing during last decade 
      <br />• Machine learning for speech processing applications
      <br />• Audio classification using machine learning
      <br />• Deep Learning for Medical Image analysis in disease classification
      <br />• Optimization of machine learning techniques for signal processing applications
      <br />• Deep Learning for Medical Image analysis in disease classification
      <br />• Improvements and amendments in machine learning for digital signal processing
      <br />• Challenges and open discussion for machine learning in signal processing
      <br />• Hands on Sessions on Python for Machine Learning Algorithms
      </p>
      
      <h4 className="my-4 font-weight-bold">
      Key Organizers & Qualifications :
      </h4>
      <p>
      • General Chair  : Dr.Y.Padma Sai, Ph.D <br />
      • General Co-chair: Dr.Lalitha Vadmani,Ph.D <br />
      • Technical Programme Chair: Dr.P.Kishore, Ph.D <br />
      • Financial Chair: Dr.V.Priyanka Brahmaiah, Ph.D <br />
      • Local Arrangements Chair: Dr. S. Rajendra Prasad, Professor -ECE, VNR VJIET <br /> </p>

      <h4 className="my-4 font-weight-bold">
      Organization or SPS Chapter:
      </h4>
      <p>
      • Host organization
        IEEE VNRVJIET Student Branch
        VNR Vignana Jyothi Institute of Engineering and Technology(VNRVJIET),
        Vignana Jyothi Nagar,
        Nizampet, Pragathi Nagar
        Hyderabad-500090
        Telangana State, India<br /> 
      • IEEE IIIT Student Branch is the joint organization <br />
      • IEEE Hyderabad section- SPS chapter will provide technical support <br /></p>

      <h4 className="my-4 font-weight-bold">
      Organization:
      </h4>
      <p>
      We are inviting the resource persons from the following organizations:
        <br />• IISc Banglore  <br />
              • NIT Patna  <br />
              • NIT Warangal  <br />
              • IIT Hyderabad  <br />
              • IIIT Hyderabad  <br />
              • IIT Chennai  <br />
              • IIIT Hyderabad  <br />
              • IIT BHU  <br />
              • JNTU Hyderabad  <br />
              • Osmania University, Hyderabad  <br />
              • TCS  <br />
              • Salicit Technologies Pvt. LtD  <br />
            </p>
        {/* We are inviting the resource persons from the following organizations:
        <br />• IISc Banglore  <br />
              • IIT Hyderabad  <br />
              • IIT Chennai  <br />
              • IIT BHU  <br />
              • IIIT Hyderabad  <br />
              • NIT Patna  <br />
              • JNTU Hyderabad  <br />
              • Osmania University, Hyderabad  <br />
              • Tata Consultancy Services Limited  <br />
              • Blaze  Automation  <br />
              • Niramai Health Analytix Private Limited  <br />
              • Sapien Biosciences Pvt ltd  <br />
              • IFIA  <br />
              • BioAxis DNA Research Centre (P) Ltd  <br />
              • AIC-CCMB  <br />
              • Salcit Technologies  <br /> */}
                    
      
      {/* <Speakers classes="page-section pt-1 pb-1"/> */}
      {/* <h4 className="my-4 font-weight-bold">
        The estimated number of SPS Members this event will impact: 50
      </h4>
      <h4 className="my-4 font-weight-bold">
        Special activities or initiatives that enhance the impact of the event
      </h4>
      <p className="mb-4">
        In addition to the lecture sessions, we have planned <br />
        a. A Panel Discussion will be planned on current trends in Biomedical
        and Healthcare applications and its impact on human lives.
        <br />
        b. Live demonstrations on Signal processing applications by industry
        experts.
        <br />
        c. An exhibit of Hardware projects and Poster Presentation.
        <br className="my-4" />
      </p> */}
    </div>
  );
}

export default EventDetails;
