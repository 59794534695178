import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import slider1 from "../assets/aiotimg.jpg";
import network from "../assets/network.jpg";
import ai from "../assets/ai.jpg";
import vnr from "../assets/vnr.JPG";
import vnr2 from "../assets/Slider/CollegePhoto2.jpg";
import posters from "../assets/about/Posters.jpeg";
import projects from "../assets/about/Projects.jpeg";

const slides = [
  {
    title: `IEEE SPS Student Branch Initiative on Latest Trends in 
  Machine Learning for Signal Processing Applications`,
    description: "10th - 12st August, 2022",
    button: "Register Now",
    image: slider1,
    link: "/registration",
    button2: "See Brochure",
    link2: vnr,
  },
  {
    title: `IEEE Student and graduate student members 
    can join SPS for 1$ for 2022`,
    button: "Join Now",
    image: network,
    link: "https://signalprocessingsociety.org/newsletter/2018/08/one-dollar-endless-opportunity-become-sps-student-member-1",
  },
  {
    title: `Submit Your Poster Today 
  Submission Deadline is 8th August 2022`,
    button: "Submit Now",
    image: ai,
    link: "https://docs.google.com/forms/d/e/1FAIpQLScPYCRoDXjL3uRhYWiDaLVAw_xkSueIdRs-BZq-0jaDQTVhAg/viewform",
    button2: "See Guidelines",
    link2: "./PosterGuidelines",
  },

  {
    title: `Submit Your Project Expo Today 
  Submission Deadline is 17th August 2022`,
    button: "Submit Now",
    image: vnr2,
    link: "https://docs.google.com/forms/d/e/1FAIpQLSedP8rppGSaGIRNSPWklADS9qZ7wOipwP5-bzF3Un0AIPtYtw/viewform",
    button2: "See Guidelines",
    link2: "./ProjectGuidelines",
  },
  {
    title: `IEEE SPS Student Branch Initiative on Latest Trends in 
  Machine Learning for Signal Processing Applications is being organized by 
  VNR Vignana Jyothi Institute of Engineering and Technology & IIIT Hyderabad  in association with IEEE SPS & WIE AG, Hyderabad Section.`,
    description: "",
    button: "Visit Site",
    image: vnr,
    link: "http://www.vnrvjiet.ac.in/",
  },
];

const Header = () => (
  <div>
    <Slider autoplay={1800}>
      {slides.map((item, index) => (
        <div
          className="main-img"
          key={index}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url('${item.image}') no-repeat top center`,
            backgroundSize: "cover",
          }}
        >
          <div className="second px-4">{item.title}</div>
          <div className="my-4">{item.description}</div>
          <a href={item.link} target="_blank" className="link-btn">
            <button className="mainbtn btn-primary">{item.button}</button>
          </a>
          {item.button2 ? (
            <a href={item.link2} target="_blank" className="link-btn ml-5">
              <button className="mainbtn btn-primary btn-info">
                {item.button2}
              </button>
            </a>
          ) : null}
        </div>
      ))}
    </Slider>
  </div>
);

export default Header;
