import React from "react";
import Committee from './AdvisoryCommittee';
import Chairs from './Chairs';
import Members from './Members';
import Students from './Students';

function Team() {
  return (
    <div>
      <Committee />
      <Chairs />
      <Members />
      <Students />
    </div>
    );
}

export default Team;
