import React from "react";
import ScheduleRow from "./ScheduleRow";
import { Table } from "reactstrap";

const Schedule = (props) => {
  return (
    <div className="container">
      {/* <div>
        <h2 className="my-4">Schedule will be uploaded shortly....</h2>
      </div> */}
      <hr className="my-4" color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              10-Aug-2022
            </td>
            <td></td>
          </tr>
          <ScheduleRow topic="Registration" time="09:00Hrs-10:00Hrs" />
          <ScheduleRow
            topic={`Inauguration Chief Guest Dr.U. B. Desai Founder Director IIT Hyderabad & Advisor , VNRVJIET Keynote Talks by Dr.Shaik Rafi Ahamed Professor-EEE
IIT Guwahati
Dr.Sumantra Dutta Roy
Professor-EE IIT Delhi`}
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-1"
            topic="Introduction to Machine Learning"
            speaker="Ms.Sireesha Jasthi, Assistant Consultant - TCS"
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-2"
            topic="Overview of various Machine Learning Algorithms"
            speaker="Dr. N. Sandhya, Professor & Head, Department of AIML & IoT - VNRVJIET"
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-3"
            topic="Biometrics: Processing Signals of Identity"
            speaker="Dr.Anoop M. Namboodiri , Associate Professor - IIITH, Ph.D (Michigan State University, USA)"
            time="15:45Hrs-17:15Hrs"
          />

          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              11-Aug-2022
            </td>
            <td></td>
          </tr>
          <ScheduleRow
            session="Session-4"
            topic="Introduction to Python for Machine Learning"
            speaker="Mr. P. Anudeep - Product Architecture Consultant ,Data Engineering, Octahedron LLC, Dallas, Texas, USA"
            time="9:00Hrs-10:00Hrs"
          />
          <ScheduleRow
            session="Session-5"
            topic="Hands on Session on Python for Machine Learning Algorithms"
            speaker="Mr. P. Anudeep - Product Architecture Consultant ,Data Engineering, Octahedron LLC, Dallas, Texas, USA"
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-6"
            topic="Latest advances in Signal Processing using Machine Learning  "
            speaker="Mr. Shailesh Sakri - Director, Automotive Audio Software, Harman International &
Chair, IEEE Signal Processing Society Bangalore Chapter, India"
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-7"
            topic="Optimization of Machine Learning Techniques for Signal Processing and Case Studies"
            speaker="Dr. M. Tanveer, Associate Professor - Ramanujan Fellow at the Discipline of Mathematics, IIT, Indore"
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-8"
            topic="Deep Learning for Medical Image analysis in disease Classification
"
            speaker="Dr.Jayanthi Sivaswamy
Professor, IIIT H"
            time="15:45Hrs-17:15Hrs"
          />
          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              12-Aug-2022
            </td>
            <td></td>
          </tr>
          <ScheduleRow
            topic="Hands on Session on Python for Machine Learning Algorithms"
            speaker="Mr. B. B. Shabarinath, Assistant Professor - Department of ECE, VNRVJIET"
            time="09:00Hrs-10:00Hrs"
          />
          <ScheduleRow
            topic="Panel Discussion on Challenges and open discussion for Machine Learning in Signal Processing"
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-9"
            topic="Best Practices of IEEE Student Branch Planning & Operations!"
            speaker="Mr.Ramneek Kalra, IEEE Impact Creator"
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-10"
            topic="Edge Computing & Telecommunication Market Hype "
            speaker="Mr.Ramneek Kalra, IEEE Impact Creator"
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-12"
            topic="Valedictory  Session"
            speaker={
              "Dr. Celia Shahnaz Professor, EEE, BUET, Dhaka,  Bangladesh, 21-22 Chair, IEEE Bangladesh Section Dr. Bharat Gupta, Professor - Dept of ECE,NIT Patna  Ms. Vijaya Durga, Scientist-G, RCI, DRDO, Hyderabad"
            }
            time="15:45Hrs-17:15Hrs"
          />
          {/* <ScheduleRow 
              session="Session-12"
              topic="Case Studies of Internet of Things enabled Healthcare Systems"
              speaker="Mr.P.Sridhar- CEO, Blaze Automation Inc,Hyderabad"
              time="15:45Hrs-17:15Hrs"
          /> */}
          {/* <tr>
            <td></td>
            <td style={{textAlign: 'center',fontWeight: '700'}}>31-Dec-2020</td>
            <td></td>
          </tr>       
          <ScheduleRow 
              session="Session-13"
              topic="Wearable IoT sensing for Health-care"
              speaker="Dr.Bikash Chandra Sahana-Assistant Professor,NIT Patna"
              time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow 
              session="Session-14"
              topic="EEG & ECG Signal Processing Techniques"
              speaker="Dr.Y.Padma Sai-Chair, WIE IEEE Hyderabad Section
              Prof - Head, ECE &Dean Students Progression, VNR VJIET"
              time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow 
              session="Session-15"
              topic="Panel Discussion on Challenges in Establishing Start-up in the Healthcare Domain"
              time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow 
              session="Session-16"
              topic="Online Quiz followed by Valedictory Keynote talk on Recent Emerging Trends in Biomedical Engineering and Healthcare"
              speaker="Dr. N. C. Shiva Prakash"
              time="15:45Hrs-17:15Hrs"
          /> */}
        </tbody>
      </Table>
      <div>
        <h4 className="my-4 font-weight-bold">Other Highlights</h4>
        <ul>
          <li>
            <strong>
              • Poster Presentations on latest trends in Machine Learning for
              Signal Processing applications on 11th August 2022 from 10.30 PM
              to 3.00PM
            </strong>
            <ol>
              <strong>Chairs</strong>
              <li>
                Dr. K. Kalyana Srinivas, Assistant Professor, ECE, VNRVJIET
              </li>
              <li>
                Dr. K.Praveen Chand, Ph.D (Carnegie Mellon University, USA), Ads
                Ranking at Pinterest, San Francisco Bay Area
              </li>
            </ol>
          </li>
          <li>
            <strong>
              • Live Demonstration of Projects on Signal Processing Applications
              on 12th August 2022 from 10.30AM to 3.00 PM{" "}
            </strong>
            <ol>
              <div>
                <strong>Chair</strong> - Dr. L. V. Rajani Kumari, Assistant
                Professor, ECE, VNRVJIET
              </div>
              <div>
                <strong>Members</strong>
              </div>
              <div>
                <li>
                  Dr.R. Sravanth Kumar, Assistant Professor, ECE, VNRVJIET
                </li>
                <li>
                  Mr. D. Ramesh Reddy, Assistant Professor, ECE, VNRVJIET{" "}
                </li>
                <li>Mr. V. Naveen Kumar, Assistant Professor, ECE, VNRVJIET</li>
              </div>
            </ol>
          </li>
          <li>
            <strong>
              Panel Discussion-1 on Challenges and Open Discussion for Machine
              Learning in Signal Processing from 2.00 PM to 3.30 PM on 12th
              August 2022.
            </strong>
            <div className="my-3">
              <h4 className="my-4 font-weight-bold">Panellists</h4>
              <ol>
                <li>
                  <div>
                    <strong>Mr.Lagudu Sateesh</strong>
                  </div>
                  <div>Senior Engg, AMD - Hyderabad </div>
                </li>
                <li>
                  <div>
                    <strong>Dr. Subodh Srivastava</strong>
                  </div>
                  <div>
                    Professor-Incharge IEEE Student Branch & Assistant
                    Professor, Dept. of ECE, NIT Patna
                  </div>
                </li>
                <li>
                  <div>
                    <strong>Dr. Ramjee Pallela</strong>
                  </div>
                  <div>
                    Chief Operating Officer at Atal Incubation Centre CCMB
                  </div>
                </li>
                <li>
                  <div>
                    <strong>Ms. G. Vijaya Durga</strong>{" "}
                  </div>
                  <div>Scientist G, RCI, DRDO, Hyderabad </div>
                </li>
              </ol>
              <div>
                Moderator - <strong>Dr. K. Anitha Sheela</strong>
              </div>
              <div className="ml-5">Professor & Head, Dept. of ECE- JNTUH</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Schedule;
