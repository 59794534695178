import React from 'react'
import EventDetails from './EventDetails';
import Speakers from './Speakers';
import Guests from './Guests'

function SPS2020()
{

 return (
      <div>
      <EventDetails />
      <Speakers />
      <Guests />
       </div>
 );

}

export default SPS2020;