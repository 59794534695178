import React from "react";

function Registration() {
  return (
    <div className="container">
      {/* <div>
        <h2 className="my-4">Registration Details</h2>
        <hr className="my-4" color="primary"/>
        <h4>Non IEEE Student Member : ₹ 200 </h4>
        <br />
        <h4> IEEE Student Member : Free</h4>
        <br />
        <h4>Non IEEE Faculty/ Industry personals: ₹ 1000</h4> <br />
        <h4>IEEE Faculty/ Industry personals : ₹ 500</h4><br />
        <h4>IEEE SPS Professionals : Free</h4>
      </div> */}
      <iframe id="ts-iframe" src="https://www.townscript.com/v2/widget/ieee-sps-student-branch-chapter-initiative-on-latest-trends-in-machine-learning-for-signal-processi-002311/booking" frameborder="0" height="600" width="80%"></iframe><link rel="stylesheet" href = "https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css" >    
    </link>
    </div>
  );
}

const formStyles = {
  btnGoogle: {
    color: "white",
    backgroundColor: "#ea4335",
  },

  btnFacebook: {
    color: "white",
    backgroundColor: "#3b5998",
  },
};

export default Registration;
