import React from "react";
import TeamMember from "./TeamMember";
import guest1 from "../assets/speakers/Shaik_Rafi.jpg";
import guest2 from "../assets/speakers/Sumantra Dutta.jpg";
import guest3 from "../assets/speakers/Bharat_Gupta.jpg";
import guest4 from "../assets/speakers/Vijaya_Durga.jpg";
import guest5 from "../assets/speakers/UBDesai.jpg";
import guest6 from "../assets/speakers/guest6.jpg";

function Guests() {
  return (
    <section className="bg-light page-section pb-1 pt-4" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">
              List Of Delegates
            </h2>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-sm-1"></div>
          <TeamMember
            src={guest1}
            alt="one"
            title="Dr. Shaik Rafi Ahamed"
            designation={`Professor, Department of EEE, IIT Guwahati, India`}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={guest2}
            alt="one"
            title="Dr.Sumantra Dutta Roy"
            designation={`Professor,  Department of Electrical Engineering ,IIT Delhi.`}
          />
          <div className="col-sm-1"></div>

          <TeamMember
            src={guest3}
            alt="one"
            title="Dr. Bharat Gupta"
            designation={`Professor, ECE , NIT Patna`}
          />
        </div>

        <div className="row my-3">
          <div className="col-sm-2"></div>
          {/* <TeamMember
                    src={guest4}
                    alt="one"
                    title="Ms. Vijaya Durga"
                    designation={`Scientist-G, RCI, DRDO, Hyderabad`}
            /> */}
          <TeamMember
            src={guest5}
            alt="one"
            title="Dr.U. B. Desai"
            designation={`Founder Director of the IIT-Hyderabad.
                     Advisor , VNR VJIET`}
          />
          <div className="col-sm-2"></div>
          <TeamMember
            src={guest6}
            alt="one"
            title="Dr. Celia Shahnaz"
            designation={`Professor, Department of EEE, BUET, Dhaka, Bangladesh Chair, IEEE Bangladesh Section`}
          />
        </div>
      </div>
    </section>
  );
}

export default Guests;
