import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

function Guidelines() {
  return (
    <section className="bg-light page-section pb-0" id="committee">
      <div className="container">
        <h1 className="my-4">PROJECT EXPO GUIDELINES</h1>
        <ListGroup>
          <ListGroupItem>
            1) Every team member should explain the entire
            content of the project thoroughly
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            2)Project must contain a degree of societal impact
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            3)Time alloted: 10 minutes
          </ListGroupItem>{" "}
          <ListGroupItem>
            {" "}
            4)Every team should bring their own laptop.
          </ListGroupItem>
          <ListGroupItem>
            5)Team must be ready with the following.... a)Outputs b)PPT c)codes
            d)Hardware kit(if necessary) e)Any other information relevant to the
            project
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            6)Formal dress code along with ID card is mandatory
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            7)For hardware projects, the team must carry Extra wires, delicate
            components in spare and batteries.
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            8)All participants should submit a video of their project
            presentation.
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            9)Project Video length should not exceed 5 minutes – including any
            titles or end-notes that the student wishes to include in the video.
          </ListGroupItem>
          <ListGroupItem>
            10)Project Video can be in any format, preferably MP4 – 720p or
            1080p and the preferred size of the file should be less than 30 MB.
          </ListGroupItem>
          <ListGroupItem>
            11)Project Video may include text, images, a video of the project
            itself, a presentation of the project in person or voice-over, or a
            combination of these elements.{" "}
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            12) In addition to college wifi, depending on the project requirements, 
            presenter should maintain mobiledata. {" "}
          </ListGroupItem>
        </ListGroup>
      </div>
    </section>
  );
}

export default Guidelines;
