import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

function Guidelines() {
  return (
    <section className="bg-light page-section pb-0" id="committee">
      <div className="container">
        <h1 className="my-4">POSTER GUIDELINES</h1>
        <ListGroup>
          <ListGroupItem>
            1)State a problem that you have solved or studied. Indicate its
            importance.
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            2)Use block diagram or flowchart to explain your work
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            3)State the key ideas or solutions.
          </ListGroupItem>{" "}
          <ListGroupItem>
            {" "}
            4)Present evidence, such as a graph or table, that you have
            solved the problem.{" "}
          </ListGroupItem>
          <ListGroupItem>
            5)Important key information should be readable from about 10 feet
            away{" "}
          </ListGroupItem>
          <ListGroupItem> 6)Keep the Title is short.</ListGroupItem>
          <ListGroupItem>
            {" "}
            7)Word count of about 200 to 500 words{" "}
          </ListGroupItem>
          <ListGroupItem>8)Text must be clear and to the point</ListGroupItem>
          <ListGroupItem>
            {" "}
            9)Use of bullet points, numbering, and headlines.
            {" "}
          </ListGroupItem>
          <ListGroupItem>
            10)Effective use of graphics, colour and fonts. 
            
          </ListGroupItem>
          <ListGroupItem> 11)Consistent and clean layout </ListGroupItem>
          <ListGroupItem>
            {" "}
            12)Include acknowledgments, your name and institutional affiliation
          </ListGroupItem>
          <ListGroupItem>
            {" "}
            13)Standard International Poster Sizes and Measurements A3 size
            posters:{" "}
            <strong>11.69in x 16.54in 297 mm X 420mm</strong>
          </ListGroupItem>
        </ListGroup>
      </div>
    </section>
  );
}

export default Guidelines;
