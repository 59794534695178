import React from "react";
import TeamMember from "./TeamMember";
import Anil from "../assets/speakers/Anil Kumar.jpeg";
import drRamjee from "../assets/speakers/Dr.Ramjee.jpg";
import anitha from "../assets/speakers/anitha.jpg";
import sateesh from "../assets/speakers/sateesh.jpg";
import Archana from "../assets/speakers/Archana.jpg"

function Panellists() {
  return (
    <section className="bg-light page-section pb-1 pt-2" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Panellists</h2>
          </div>
        </div>
        <div className="row my-3">
          <TeamMember
            cols={4}
            src={sateesh}
            alt="one"
            title="Mr.Lagudu Sateesh"
            designation={`Senior Engg, AMD`}
          />
          <TeamMember
            cols={4}
            src={Anil}
            alt="one"
            title="Anil Kumar Vuppala"
            designation={`Associate Professor, Ph.D
            IIT-Kharagpur`}
          />
          <TeamMember
            cols={4}
            src={drRamjee}
            alt="one"
            title="Dr. Ramjee Pallela"
            designation={`COO at AIC-CCMB`}
          />
        </div>

        <div className="row my-3">
          <div className="col-sm-2"></div>
          {/* <TeamMember cols={4} 
                    src={vijaya}
                    alt="one"
                    title="Ms.G. Vijaya Durga,"
                    designation={`Scientist-G, RCI, DRDO, Hyderabad`}
            /> */}
          <TeamMember
            cols={4}
            src={anitha}
            alt="one"
            title="Dr. K. Anitha Sheela"
            designation={`Professor & Head, Dept. of ECE- JNTUH`}
          />
           <TeamMember
            cols={4}
            src={Archana}
            alt="one"
            title="Archana K Bhange"
            designation={`Senior Assistant professor,Dept. of ECE,VNRVJIET`}
          />
        </div>
      </div>
    </section>
  );
}

export default Panellists;
